// Use this to write your custom SCSS


// ................. Header image ......................... //

.image-wrapper.bg-full.bg-image .text-white p {
    color: #fff;
    
}

.image-wrapper.bg-full.bg-image p em{
    color: #fff;
    font-weight: 300;
}

.image-wrapper.bg-full.bg-image p strong{
    font-size: 26px;
}

.image-wrapper.bg-full.bg-image h1{
    font-weight: 100;
}




// ................. Body......................... //

html{
    // background-color: 
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, #e8f5f6 100%);     
    background-size: cover;
    background-attachment: fixed;
}

body{background: none;}





.image-wrapper.bg-full {
   background-size: auto;
   background-position: center center; 
   background-repeat: no-repeat; 
   background-attachment: scroll!important;
}


// ................. links ......................... //

a {text-decoration: none!important;}

// ................. Color......................... //
.gradient-1 {
    background-image: linear-gradient(120deg,#192728 10%,#bee2e6 100%);
}

.gradient-2 {
    background-image: linear-gradient(120deg,#192728 10%,#7c989a 100%);
}

.gradient-3 {
    background-image: linear-gradient(120deg,#7c989a 10%,#aed0d4 100%);
}

// ................. Color......................... //
mark {
    background-color: $secondary;
    padding: 6px 14px 6px;   
    border-radius: 0.2rem;
    display:  inline-flex;
    
}

// ................. navbar......................... //
/*  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: $green;
}
.dropdown-item.active, .dropdown-item:active {
  color: $green;
} */

.navbar-nav .nav-link {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 12px!important;
    padding-right: 12px!important;
}

.display-1,.display-2,.display-3,.display-4,.display-5,.display-6{
    font-weight: 100;
}

.btn{
    font-weight: 500;
}


// ........... typography ............. //


body, p{
    color: #000;
    font-weight: 400;
    font-family: "Open-Sans", sans-serif;   
    }

strong {
    font-weight: 600;
    font-size: 18px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    letter-spacing: normal;
//  text-shadow: 1px 1px 5px #00000070;
}

// span p {margin-bottom: 0px;}


// ........... contactform............. //
.form-control {
    color: #60697b;
    box-shadow: none;
    border: 1px solid rgba(8,60,130,.07);
    border-radius: 0.4rem;
    /* box-shadow: 0 0 1.25rem rgba(30,34,40,.04); */
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #ffffff;
}


// ........... link color............ //
.bg-white a:not(.btn){
color: #275249;
font-weight: bold;
}

.bg-primary a:not(.btn){
    color: #275249;
    font-weight: bold;
    }
    
// ........... offcanvas........... //

.navbar-collapse.offcanvas.offcanvas-nav a.nav-link,.navbar-collapse.offcanvas.offcanvas-nav a.dropdown-item {
    color: #275249!important;
}

 img.shadow-lg{
    box-shadow: 0 .25rem 1.75rem rgba(30,34,40,0.4)!important;
}


// ........... speciale button ............ //

.btn.btn-expand:before
{
width: 2.1rem;
height: 2.1rem;
}

.btn.btn-expand:hover:before {
    width: 100%;
 }

 i.uil.uil-arrow-right{
 width: 2.2rem; 
 height:2.2rem;
}

.btn.btn-expand span {

    line-height: 1;
}

.professionals.btn.btn-expand:before {
     width: 1.7rem;
     height: 1.7rem;    
  }
  .professionals.btn.btn-expand:hover:before{
    width: 100%;
    height: 1.7rem;    
 }

  .professionals i.uil.uil-arrow-right {
    width: 1.7rem;
    height: 1.7rem;
}


// ........... speciale button ............ //
footer .widget-title{
    color: #fff;
}

// ........... blockquote ............ //
blockquote {
      font-weight: 400;
      font-style: italic;
}

// ........... UL List column ............ //

.card.white.rounded-3.shadow-lg .weergeven-in-2-kolommen-ja ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
   }

   .card.white.rounded-3.shadow-lg ul {
    padding-left: 16px;
    padding-top: 15px;
   }

  section .container ul {
    list-style-type: none;
    list-style-position: outside;
    position: relative
}

section .container ul li {
    padding-left: 6px;
    padding-bottom: 10px;
   // display:flex;
  }

section .container ul li::marker {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    // position: absolute;
    margin-right: 10px;
  
}

section .container ul li::marker {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "";
    color: #bee2e6;
}

section .container ul ul li::marker {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "";
    color: #c7c7c7;
}

.card.white.rounded-3.shadow-lg .weergeven-in-2-kolommen-ja p:has(+ ul)  {
    margin-bottom: 0px;
}

.card.white.rounded-3.shadow-lg .weergeven-in-2-kolommen-nee p:has(+ ul) {
    margin-bottom: 0px;
}

// ........... UL List extra witruimte en hr in lijst op 'Kim Biesmans' pagina ............ //


.page-id-1969 section .container ul li {
        margin-left: -6px;
        padding-bottom: 10px;
        border-bottom: 1px dotted #bee2e6;
        margin-bottom: 20px;
        display:flex;
    } 



// ........... Custom shadow ............ //
.shadow-xl {
    box-shadow:
    rgba(30,34,40,.03) 0 -2px 12px,
    rgba(30,34,40,.03) 0 4px 12px,
    rgba(30,34,40,.03) 0 8px 12px,
    rgba(30,34,40,.03) 0 16px 12px,
    rgba(30,34,40,.04) 0 32px 16px!important;
}

// ........... Pdf links ............ //
div.card:not(.gradient-2) a:not(.privacyvw)[href$=".pdf"] {
    // background: url(images/pdf-icon.png) no-repeat 0 50%;
      color: #000;
      font-weight: 500;
      background-color: #fff;
      padding: 12px;
      border-radius: 5px;
      box-shadow:
      rgba(30,34,40,.03) 0 -2px 12px,
      rgba(30,34,40,.03) 0 4px 12px,
      rgba(30,34,40,.03) 0 8px 12px,
      rgba(30,34,40,.03) 0 16px 12px,
      rgba(30,34,40,.04) 0 32px 16px!important;
      display: block;
}


div.card:not(.gradient-2) a:not(.privacyvw)[href$=".pdf"]::before{
font-family: "Font Awesome 5 Free";
content: "\f1c1";
font-size: 20px;
margin-right: 10px;
}


// ........... Footer ............ //

footer, footer a{
    color:#fff!important;    
}

footer a{
    font-weight: 300!important; 
}


// ........... header image ............ //
.image-wrapper.bg-full.mobile {
    background-position: 70% 50%;
   
}


@media only screen and (max-width: 992px) {

.image-wrapper.bg-full:after {
    background-color: rgba(0, 0, 0, 0.438);
   width: 100%;
   height: 100%;
   position: absolute;
  z-index: 0;
  content: "";
  border-radius: 0.4rem!important;
}
}


// ........... dropdown menu ............ //

ul.dropdown-menu.sub-menu.depth_1{

    left: 205px;
    top: 0px!important;
    margin-top: 0px!important;
}


@media only screen and (min-width: 1400px) {
ul.depth_0 .dropdown-toggle::after {
    font-family: Unicons;
     content: "\e931";
}}


.nav-link.dropdown-toggle::after {
margin-top: -3px!important;   
}

ul.dropdown-menu {
top: 50px!important;
}



// ........... woocommerce correcties ............ //

.woocommerce div.product form.cart .button {
//    height: 48px;
}

.woocommerce  .input-group-text {
    padding: 0.375rem 0.75rem;

}

.offcanvas {background: #fff;}
.offcanvas  {background: #fff;}

.offcanvas.show .offcanvas {background: #fff;}
.offcanvas.show .offcanvas {background: #fff;}
.offcanvas.show .offcanvas-header {background:  #f7f7f7}


.woocommerce-js  .offcanvas-header .btn-close {
    margin-right: 0;
    margin-top: -25px;
  }


  .woocommerce-js .btn-close:before {
    background: rgb(0 0 0);
  }

  .woocommerce span.onsale {
    background-color: #62bbc5;
    padding: 8px;
    font-size: 14px;
  }

  .woocommerce div.product form.cart .button, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce button.button.alt, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button {
    color: #fff;
    background-color: #62bbc5;
    border-color: #62bbc5;
}

.woocommerce div.product form.cart .button:hover, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover, .woocommerce button.button.alt:hover, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button:hover {
    color: #fff;
    background-color: #76cbd4;
    border-color: #76cbd4;
    text-decoration: none;
}


.woocommerce-mini-cart__buttons .btn.btn-primary.btn-lg.d-block{
    background-color: #62bbc5;
    border-color: #62bbc5;    
}

:root :where(.wp-element-button, .wp-block-button__link) {
    background-color: #62bbc5;
   
}

.product_type_simple.add_to_cart_button.btn-primary{
    background-color: #62bbc5;
    border-color: #62bbc5;    
}

.cart-toggler{
  //  padding-top: 0.6rem;
  //  padding-bottom: 0.6rem;
}

.shadow-lg img.attachment-woocommerce_thumbnail {
    box-shadow: none!important;
}

.product .card-body{
    padding: 20px;
}

@media screen and (max-width: 549px) {
    form.cart .single_add_to_cart_button{
        margin-top: 20px;
       }         
    }


    .h5.offcanvas-title{
        font-size: 1.3rem;
        color: #5c6e72;
    }

    .offcanvas-body {
        padding-top: 25px;
        padding-bottom: 0;
    }


    
// ........... Active Campaign correcties ............ //

// checkbox werd niet weergegeven
._form input { 

    -webkit-appearance: checkbox !important;
    appearance: checkbox !important;
}



  

